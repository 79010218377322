/* eslint-disable camelcase */
import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import prepaidStoreModule from '../prepaidStoreModule';

export default function usePrepaidDetail(props) {
  const STORE_MODULE_NAME = 'prepaid';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, prepaidStoreModule);
  }

  const toastification = toast();
  const { t } = useI18nUtils();
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const resetModal = () => {
    resetItemLocal();
  };
  const data = ref([]);
  const totalAmount = computed(() => {
    let temp = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const eachItem of data.value) {
      if (eachItem.isAdd) {
        temp += eachItem.amount;
      } else {
        temp -= eachItem.amount;
      }
    }
    return temp;
  });

  const fetchData = () => {
    store
      .dispatch('prepaid/getPrepaidDetailByContract', { studentId: itemLocal.value.studentId })
      .then(response => {
        data.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const showModal = () => {
    resetModal();
    fetchData();
  };

  return {
    itemLocal,
    totalAmount,
    data,
    resetItemLocal,
    resetModal,
    fetchData,
    showModal,
    t,
  };
}
